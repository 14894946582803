import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../../components/Layout/Layout';
import { Intro } from '../../modules/Intro/Intro';


const DownloadWindowsPage = (): ReactElement => (
    useEffect(()=>{
        setTimeout(function(){
            window.location.replace('https://sketchwow.s3.us-west-1.amazonaws.com/SketchWow-2.4.3-setup.zip')
        }, 100)
        

        }, []),

  <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<>Thank you for downloading the latest Windows version.</>}
      
    />
  </Layout>
);

export default DownloadWindowsPage;
